import React from "react";
import { MobileNavToggle } from "@deriv-com/blocks";
import { Button } from "@deriv/quill-design";
// eslint-disable-next-line import/no-unresolved
import { LabelPairedGridLgBoldIcon } from "@deriv/quill-icons/LabelPaired";
import goldJson from "../../../content/sites/gold.json";

export const NavigationButtons = () => {
  const is_logged_in = false;
  const { buttons } = goldJson.navigation;

  return (
    <div className="flex items-center gap-gap-md">
      {buttons &&
        (is_logged_in ? (
          <Button
            variant="secondary"
            colorStyle="black"
            size="lg"
            icon={LabelPairedGridLgBoldIcon}
            iconPosition="start"
          >
            {buttons.tradershub}
          </Button>
        ) : (
          <>
            <Button variant="secondary" colorStyle="black" size="lg">
              {buttons.login}
            </Button>
            <Button
              size="lg"
              className="hidden lg:block bg-solid-mustard-800 hover:!bg-solid-mustard-800"
            >
              {buttons.account}
            </Button>
          </>
        ))}
      <MobileNavToggle data-cy="hamburger-menu" />
    </div>
  );
};

export default NavigationButtons;
