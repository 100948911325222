import { CustomLink } from "@deriv-com/components";
// import { BrandDerivWordmarkCoralIcon } from "@deriv/quill-icons/Logo";
import ExportedImage from "next-image-export-optimizer";
import gold from "../../../content/sites/gold.json";

export const NavigationLogo = () => {
  const { logo } = gold.navigation;
  return (
    <CustomLink href={"/"}>
      {/* <BrandDerivWordmarkCoralIcon width={73} height={24} /> */}
      <ExportedImage
        src={logo.src}
        width={104}
        height={24}
        alt={logo.alt}
        placeholder="empty"
      />
    </CustomLink>
  );
};

export default NavigationLogo;
