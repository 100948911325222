"use client";

import React from "react";
import { NavigationBlock } from "@deriv-com/blocks";
import NavigationButtons from "./nav.buttons";
import NavigationLogo from "./nav.logo";
import goldJson from "../../../content/sites/gold.json";
import { NavLinkItems } from "@deriv-com/providers";

const Navigation = () => {
  const navItems = goldJson.navigation.navItems as NavLinkItems;
  return (
    <NavigationBlock
      renderButtons={NavigationButtons}
      renderLogo={NavigationLogo}
      items={navItems}
    />
  );
};

export default Navigation;
