"use client";

import React from "react";
import { LanguageProvider } from "@deriv-com/providers";
import { langItems } from "./languages";

const ProviderLanguage = ({ children }: { children: React.ReactNode }) => {
  return (
    <LanguageProvider langItems={langItems} activeLanguage={langItems.en}>
      {children}
    </LanguageProvider>
  );
};

export default ProviderLanguage;
