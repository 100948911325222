"use client";

import { Footer } from "@deriv-com/blocks";
import { Text, qtMerge } from "@deriv/quill-design";
import GoldJson from "../../../content/sites/gold.json";
import { DescLink, FooterProps } from "@/types";
import { CustomLink } from "@deriv-com/components";

const Link = ({ item }: { item: DescLink }) => (
  <CustomLink
    size="sm"
    className={qtMerge("inline font-bold underline")}
    href={item.href}
  >
    {item.text}
  </CustomLink>
);

const DescriptionContent = ({
  description,
}: {
  description: FooterProps["description"];
}) => {
  return (
    <Text size="sm">
      {description.map((item, index) => {
        return item.type === "link" ? (
          <Link key={index} item={item} />
        ) : item.type === "text" ? (
          <span key={index}>{item.value}</span>
        ) : null;
      })}
    </Text>
  );
};

export const MainFooter = () => {
  const { items, description } = GoldJson.footer as FooterProps;

  return (
    <Footer.FooterBlock
      descriptionContent={() => (
        <DescriptionContent description={description} />
      )}
    >
      <Footer.MainNavContent items={items} />
    </Footer.FooterBlock>
  );
};

export default MainFooter;
